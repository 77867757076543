export interface RSSSource {
  url: string;
  name: string;
  category: string;
  description: string;
}

// 初始默认RSS源
const initialDefaultSources: RSSSource[] = [
  {
    url: "https://hub.slarker.me/jike/topic/563a2995306dab1300a32227?filterout=%E5%8D%B3%E5%8F%8B%7C%E5%8D%B3%E5%88F%BB",
    name: "产品经理的日常",
    category: "产品",
    description: "行业观察，产品心得，日常吐槽，好文推荐~"
  },
  {
    url: "https://www.woshipm.com/feed",
    name: "人人都是产品经理",
    category: "产品",
    description: "产品管理和产品设计相关文章。"
  },
  {
    url: "https://hub.slarker.me/jike/topic/564c2ce508987312006e2326",
    name: "产品安利社",
    category: "产品",
    description: "即刻圈子，分享优秀的软件产品。"
  },
  {
    url: "https://hub.slarker.me/jike/topic/55d81b4b60b296e5679785de",
    name: "奇趣网站收藏家",
    category: "产品",
    description: "收集互联网上有意思的网站。"
  },
  {
    url: "https://hub.slarker.me/v2ex/tab/creative",
    name: "v2ex创意",
    category: "产品",
    description: "分享创造设计等"
  },
  {
    url: "https://www.decohack.com/feed",
    name: "Decohack",
    category: "产品",
    description: "分享独立开发者灵感和项目。"
  },
  {
    url: "https://hub.slarker.me/jike/topic/63579abb6724cc583b9bba9a",
    name: "AI 探索站",
    category: "AI",
    description: "即刻圈子，探索人工智能的边界。"
  },
  {
    url: "https://sanhua.himrr.com/daily-news/feed",
    name: "三花 AI 快讯",
    category: "AI",
    description: "专注于人工智能领域最新动态和技术进展。"
  },
  {
    url: "https://www.ftium4.com/rss.xml",
    name: "龙爪槐守望者",
    category: "周刊",
    description: "帮助交互设计师成长的平台。"
  },
  {
    url: "https://quail.ink/dingyi/feed/atom",
    name: "DEX 周刊",
    category: "周刊",
    description: "关于产品、设计、前端、软件等内容的精华资讯。"
  },
  {
    url: "https://www.ezindie.com/feed/rss.xml",
    name: "让小产品的独立变现更简单",
    category: "周刊",
    description: "分享独立开发产品实现盈利的案例。"
  },
  {
    url: "https://www.ruanyifeng.com/blog/atom.xml",
    name: "阮一峰的网络日志",
    category: "周刊",
    description: "科技爱好者周刊"
  },
  {
    url: "https://hub.slarker.me/jike/topic/57079a1526b0ab12002c29da",
    name: "大产品小细节",
    category: "设计",
    description: "关注产品中的微小而温暖的细节。"
  },
  {
    url: "https://hub.slarker.me/jike/topic/5a6e94ef7a263000174589cc",
    name: "设计师日常",
    category: "设计",
    description: "设计师们看过来"
  },
  {
    url: "https://www.ifanr.com/feed",
    name: "爱范儿",
    category: "科技",
    description: "报道未来科技、新酷产品。"
  }
  // ... 其他初始默认源
];

// 从初始默认RSS源中提取唯一的分类
const initialDefaultCategories = Array.from(new Set(initialDefaultSources.map(source => source.category)));

// 获取当前默认RSS源
export const getDefaultRSSSources = (): RSSSource[] => {
  const savedDefaultSources = localStorage.getItem('defaultRSSSources');
  return savedDefaultSources ? JSON.parse(savedDefaultSources) : initialDefaultSources;
};

// 设置新的默认RSS源
export const setDefaultRSSSources = (sources: RSSSource[]): void => {
  localStorage.setItem('defaultRSSSources', JSON.stringify(sources));
};

// 从本地存储加载RSS源
export const loadRSSSources = (): RSSSource[] => {
  const savedSources = localStorage.getItem('rssSources');
  console.log('Loaded RSS sources:', savedSources);
  return savedSources ? JSON.parse(savedSources) : getDefaultRSSSources();
};

// 保存RSS源到本地存储
export const saveRSSSources = (sources: RSSSource[]): void => {
  localStorage.setItem('rssSources', JSON.stringify(sources));
};

// 重置RSS源为默认配置并返回默认源
export const resetToDefaultSources = (): RSSSource[] => {
  const defaultSources = getDefaultRSSSources();
  localStorage.setItem('rssSources', JSON.stringify(defaultSources));
  return defaultSources;
};

// 将当前RSS源保存为新的默认配置
export const saveCurrentAsDefault = (): void => {
  const currentSources = loadRSSSources();
  setDefaultRSSSources(currentSources);
};

// 加载分类
export const loadCategories = (): string[] => {
  const savedCategories = localStorage.getItem('rssCategories');
  console.log('Loaded categories:', savedCategories);
  return savedCategories ? JSON.parse(savedCategories) : initialDefaultCategories;
};

// 保存分类
export const saveCategories = (categories: string[]): void => {
  localStorage.setItem('rssCategories', JSON.stringify(categories));
};