import React from 'react';

interface RSSItem {
  title: string;
  link: string;
  pubDate: string;
  description?: string;
}

interface RSSCardProps {
  title: string;
  description: string;
  items: RSSItem[];
  lastUpdated: Date | string;
  category: string;
  isSelected: boolean;
  status: 'ok' | 'error' | 'loading';
  isMobile: boolean;
}

function RSSCard({ title, description, items, lastUpdated, category, isSelected, status, isMobile }: RSSCardProps) {
  console.log('Rendering RSSCard:', { title, description, items, lastUpdated, category, isSelected, status });

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('zh-CN', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className={`rss-card ${isSelected ? 'selected' : ''} ${status} ${isMobile ? 'mobile-card' : ''}`}>
      <div className="rss-card-header">
        <div className="rss-card-title-category">
          <h2>{title}</h2>
          <div className="rss-card-category">{category}</div>
        </div>
        {description && <div className="rss-card-description">{description}</div>}
      </div>
      <div className="rss-card-content">
        {status === 'error' ? (
          <p>无法加载 RSS 源内容。请检查源 URL 或稍后再试。</p>
        ) : status === 'loading' ? (
          <p>加载中...</p>
        ) : items.length === 0 ? (
          <p>暂无内容</p>
        ) : (
          <ul>
            {items.slice(0, 10).map((item, index) => (
              <li key={index}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.title || '无标题'}
                  <span className="item-date"> - {formatDate(item.pubDate)}</span>
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="rss-card-footer">
        最后更新: {formatDate(lastUpdated.toString())}
      </div>
    </div>
  );
}

export default RSSCard;