import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import axios from 'axios';
import Parser from 'rss-parser';
import * as FeedReader from 'feed-reader';
import RSSCard from './components/RSSCard';
import RSSConfig from './components/RSSConfig';
import { RSSSource, loadRSSSources, saveRSSSources, loadCategories } from './config/rssConfig';
import { themes, ThemeType, ThemeColors } from './config/themeConfig';
import './App.css';
import { parseString } from 'xml2js';
import { QRCodeSVG } from 'qrcode.react'; // 将 QRCode 改为 QRCodeSVG

interface RSSItem {
  title: string;
  link: string;
  pubDate: string;
  description: string;
  content?: string;
  image?: string;
  author?: string;
}

interface RSSFeed {
  title: string;
  items: RSSItem[];
  lastUpdated: Date;
  category: string;
  status: 'ok' | 'error';
  description: string;
}

interface XMLResult {
  rss?: {
    channel: any[];
  };
  feed?: any;
}

// 在文件顶部添加这个类型定义
interface FeedReaderResult {
  entries?: any[];
  description?: string;
  // 添加其他可能的属性
}

async function parseXML(xml: string): Promise<any> {
  return new Promise((resolve, reject) => {
    parseString(xml, (err: Error | null, result: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
}

function createRSSFeed(data: Partial<RSSFeed>): RSSFeed {
  return {
    title: '',
    category: '',
    items: [],
    lastUpdated: new Date(),
    status: 'error',
    description: '',
    ...data
  };
}

// 在 App 组件外部定义这个函数
const getCachedData = (key: string) => {
  const cached = localStorage.getItem(key);
  if (cached) {
    const { data, timestamp } = JSON.parse(cached);
    if (new Date().getTime() - timestamp < 30 * 60 * 1000) { // 30分钟缓存
      return data;
    }
  }
  return null;
};

// 新的 MixedContent 组件
const MixedContent: React.FC<{ rssData: RSSFeed[], categories: string[] }> = ({ rssData, categories }) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [visibleItems, setVisibleItems] = useState(20);

  const allItems = useMemo(() => rssData.flatMap(feed => 
    feed.items.map(item => ({
      ...item,
      feedTitle: feed.title,
      feedCategory: feed.category
    }))
  ), [rssData]);

  const filteredItems = useMemo(() => 
    selectedCategory 
      ? allItems.filter(item => item.feedCategory === selectedCategory)
      : allItems
  , [allItems, selectedCategory]);

  const sortedItems = useMemo(() => filteredItems.sort((a, b) => 
    new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime()
  ), [filteredItems]);

  const itemsToShow = sortedItems.slice(0, visibleItems);

  const loadMore = () => {
    setVisibleItems(prev => prev + 20);
  };


  const stripHtml = (html: string) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('zh-CN', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  return (
    <div className="mixed-content-container">
      <nav className="category-nav">
        <button 
          onClick={() => setSelectedCategory(null)} 
          className={selectedCategory === null ? 'active' : ''}
        >
          全部
        </button>
        {categories.map(category => (
          <button 
            key={category} 
            onClick={() => setSelectedCategory(category)}
            className={selectedCategory === category ? 'active' : ''}
          >
            {category}
          </button>
        ))}
      </nav>
      <div className="mixed-content">
        {itemsToShow.map((item, index) => {
          const strippedContent = stripHtml(item.content || item.description || '');
          const isTruncated = strippedContent.length > 300;
          const displayContent = isTruncated ? truncateText(strippedContent, 300) : strippedContent;

          return (
            <div key={index} className="mixed-item">
              <div className="mixed-item-content">
                <h3>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.title}
                  </a>
                </h3>
                <div className="mixed-item-description">
                  {displayContent}
                </div>
                <a href={item.link} target="_blank" rel="noopener noreferrer" className="read-more">
                  阅读原文
                </a>
                {item.image && (
                  <div className="mixed-item-image">
                    <img src={item.image} alt={item.title} loading="lazy" />
                  </div>
                )}
              </div>
              <div className="mixed-item-meta">
                <span className="mixed-item-feed">{item.feedTitle}</span>
                <span className="mixed-item-category">{item.feedCategory}</span>
                <span className="mixed-item-date">{formatDate(item.pubDate)}</span>
                {item.author && <span className="mixed-item-author">作者: {item.author}</span>}
              </div>
            </div>
          );
        })}
        {itemsToShow.length < sortedItems.length && (
          <button onClick={loadMore} className="load-more-button">
            加载更多
          </button>
        )}
      </div>
    </div>
  );
};

// 在组件外部定义这个函数
const logError = (error: any, feedName: string) => {
  console.error(`获取 ${feedName} 的 RSS 失败:`, error);
  if (axios.isAxiosError(error)) {
    console.error('请求配置:', error.config);
    if (error.response) {
      console.error('响应状态:', error.response.status);
      console.error('响应数据:', error.response.data);
    } else if (error.request) {
      console.error('请求已发送但没有收到响应');
      console.error('请求:', error.request);
    } else {
      console.error('错误:', error.message);
    }
  }
};

// 添加这个函数来检测是否为移动设备
const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// 修改 getProxyUrl 函数
const getProxyUrl = () => {
  if (process.env.REACT_APP_PROXY_URL) {
    return process.env.REACT_APP_PROXY_URL;
  }
  if (process.env.NODE_ENV === 'development') {
    return isMobileDevice() ? 'https://api.allorigins.win/raw?url=' : 'http://localhost:5001/proxy?url=';
  }
  return '/proxy?url=';
};

// 添加一个新的函数来获取 RSS 数据
const fetchRSSData = async (url: string) => {
  const proxyUrl = getProxyUrl();
  const fullUrl = `${proxyUrl}${encodeURIComponent(url)}`;
  
  try {
    const response = await axios.get(fullUrl, { 
      timeout: 15000,
    });
    
    // 检查响应的 Content-Type 头
    const contentType = response.headers['content-type'];
    if (!contentType || (!contentType.includes('application/xml') && !contentType.includes('text/xml'))) {
      throw new Error('代理服务器返回了非 XML 数据');
    }
    
    console.log('使用代理获取成功:', response.data);
    return response.data;
  } catch (error) {
    console.error('使用代理获取失败:', error);
    
    // 尝试直接请求 RSS 源
    try {
      const response = await axios.get(url, { timeout: 15000 });
      console.log('直接获取成功:', response.data);
      return response.data;
    } catch (directError) {
      console.error('直接获取也失败了:', directError);
    }
    
    console.log('尝试使用 RSS to JSON 服务');
    const rssToJsonUrl = `https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(url)}`;
    try {
      const jsonResponse = await axios.get(rssToJsonUrl);
      console.log('RSS to JSON 服务获取成功:', jsonResponse.data);
      return jsonResponse.data;
    } catch (jsonError) {
      console.error('RSS to JSON 服务也失败了:', jsonError);
      throw new Error('无法获取 RSS 数据');
    }
  }
};

// 在文件顶部添加这个类型定义
type CustomFeed = {
  title: string;
  description: string;
  link: string;
};

type CustomItem = {
  title: string;
  link: string;
  content: string;
  contentSnippet: string;
  isoDate: string;
};

// 创建 rss-parser 实例
const rssParser = new Parser();

function getDescription(feed: any, fallback: string): string {
  if (typeof feed.description === 'string') {
    return feed.description;
  }
  if (feed.feed && typeof feed.feed.description === 'string') {
    return feed.feed.description;
  }
  return fallback;
}

function App() {
  const [rssData, setRssData] = useState<RSSFeed[]>([]);
  const [rssSources, setRssSources] = useState<RSSSource[]>(loadRSSSources());
  const [lastFetchTime, setLastFetchTime] = useState<number>(0);
  const [showConfig, setShowConfig] = useState(false);
  const [password, setPassword] = useState('');
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const hour = new Date().getHours();
    return hour < 6 || hour >= 18;
  });
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [categories, setCategories] = useState<string[]>(loadCategories());
  const categoryRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const [viewMode, setViewMode] = useState<'card' | 'stream'>('card');
  const [streamPage, setStreamPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const streamContainerRef = useRef<HTMLDivElement>(null);
  const [showSkinSelector, setShowSkinSelector] = useState(false);
  const [currentTheme, setCurrentTheme] = useState<ThemeType>('nature');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('feed'); // 新增: 用于底部标签栏
  const [isMobile, setIsMobile] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  // 获取当前主题的颜色
  const themeColors: ThemeColors = isDarkMode ? themes[currentTheme].dark : themes[currentTheme].light;

  // 应用主题颜色
  useEffect(() => {
    Object.entries(themeColors).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
  }, [themeColors]);

  useEffect(() => {
    const checkMobile = () => {
      const isMobile = window.innerWidth <= 768;
      setIsMobile(isMobile);
      document.body.classList.toggle('mobile-device', isMobile);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const showButtons = scrollY > 200; // 当滚动超过200px时显示按钮
      setShowScrollButtons(showButtons);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const fetchAllRSSData = useCallback(async () => {
    const now = Date.now();
    if (now - lastFetchTime < 600000) {
      console.log('跳过获取，距离上次获取不到10分钟');
      return;
    }

    console.log('正在获取RSS数据...');

    try {
      const feeds = await Promise.all(
        rssSources.map(async (source: RSSSource) => {
          try {
            console.log(`正在获取 ${source.name} 的RSS，URL: ${source.url}`);
            const data = await fetchRSSData(source.url);
            
            let items: RSSItem[] = [];

            if (typeof data === 'string') {
              // XML 数据
              console.log('收到XML数据:', data);
              const parser = new DOMParser();
              const xmlDoc = parser.parseFromString(data, "text/xml");
              const entries = xmlDoc.getElementsByTagName('item');
              items = Array.from(entries).map((entry: any) => ({
                title: entry.getElementsByTagName('title')[0]?.textContent || '',
                link: entry.getElementsByTagName('link')[0]?.textContent || '',
                pubDate: entry.getElementsByTagName('pubDate')[0]?.textContent || '',
                description: entry.getElementsByTagName('description')[0]?.textContent || ''
              }));
            } else if (data.items) {
              // JSON 数据 (来自 RSS to JSON 服务)
              console.log('收到JSON数据:', data);
              items = data.items.map((item: any) => ({
                title: item.title || '',
                link: item.link || '',
                pubDate: item.pubDate || '',
                description: item.description || ''
              }));
            }

            console.log(`解析后的 ${source.name} 数据:`, items);

            return createRSSFeed({
              title: source.name,
              category: source.category,
              items: items,
              lastUpdated: new Date(),
              status: 'ok',
              description: source.description
            });
          } catch (error) {
            console.error(`获取 ${source.name} 的RSS失败:`, error);
            return createRSSFeed({
              title: source.name,
              category: source.category,
              items: [],
              lastUpdated: new Date(),
              status: 'error',
              description: `获取失败: ${error instanceof Error ? error.message : '未知错误'}`
            });
          }
        })
      );

      console.log('所有RSS源已获取:', feeds);
      setRssData(feeds);
      setLastFetchTime(now);
    } catch (error) {
      console.error('获取RSS数据时发生错误:', error);
    }
  }, [rssSources, lastFetchTime]);

  // 使用 fetchAllRSSData 替换原来的 fetchRSSData
  useEffect(() => {
    fetchAllRSSData();
    const interval = setInterval(fetchAllRSSData, 600000); // 每10分钟尝试更新一次
    return () => clearInterval(interval);
  }, [fetchAllRSSData]);

  useEffect(() => {
    const hour = new Date().getHours();
    const shouldBeDarkMode = hour < 6 || hour >= 18;
    setIsDarkMode(shouldBeDarkMode);
    document.body.classList.toggle('dark-mode', shouldBeDarkMode);
  }, []);

  const handleSaveConfig = (newSources: RSSSource[]) => {
    console.log('Saving new configuration...', newSources);
    setRssSources(newSources);
    saveRSSSources(newSources);
    
    // 更新 rssData 中的分类
    setRssData(prevData => prevData.map(feed => {
      const updatedSource = newSources.find(source => source.url === feed.title);
      if (updatedSource) {
        return { ...feed, category: updatedSource.category };
      }
      return feed;
    }));

    // 更新分类列表
    const uniqueCategories = Array.from(new Set(newSources.map(source => source.category)));
    setCategories(uniqueCategories);

    // 重新获取 RSS 数据
    fetchAllRSSData();
  };

  const handleCategoriesChange = (newCategories: string[]) => {
    setCategories(newCategories);
    // 如果需要，这里可以添加其他逻辑来处理分类变化
  };

  const handleConfigClick = () => {
    setShowPasswordModal(true);
  };

  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (password === '123456') {
      setIsPasswordValid(true);
      setShowPasswordModal(false);
      setShowConfig(true);
      setPassword('');
      // 设置密码有效期为1小时
      const validUntil = new Date().getTime() + 60 * 60 * 1000;
      localStorage.setItem('passwordValidUntil', validUntil.toString());
    } else {
      alert('密码错误');
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => {
      const newMode = !prevMode;
      document.body.classList.toggle('dark-mode', newMode);
      return newMode;
    });
  };

  const handleCategoryClick = (category: string | null) => {
    setSelectedCategory(category);
    setShowConfig(false); // 添加这一行，确保返回到 RSS 列表页面
    if (category && categoryRefs.current[category]) {
      categoryRefs.current[category].current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // 使用 useMemo 来缓存分类后的 RSS 数据
  const groupedRSSData = useMemo(() => {
    return rssData.reduce((acc, feed) => {
      if (!acc[feed.category]) {
        acc[feed.category] = [];
      }
      acc[feed.category].push(feed);
      return acc;
    }, {} as Record<string, RSSFeed[]>);
  }, [rssData]);

  const renderRSSCards = () => {
    // console.log('Rendering RSS cards, data:', rssData);
    if (rssData.length === 0) {
      return <div>加载中...</div>;
    }

    // 按照分类排序 RSS 数据
    const sortedRSSData = [...rssData].sort((a, b) => {
      const categoryIndexA = categories.indexOf(a.category);
      const categoryIndexB = categories.indexOf(b.category);
      if (categoryIndexA !== categoryIndexB) {
        return categoryIndexA - categoryIndexB;
      }
      return a.title.localeCompare(b.title);
    });

    let feedsToRender: RSSFeed[];
    if (selectedCategory === null) {
      feedsToRender = sortedRSSData;
    } else {
      feedsToRender = sortedRSSData.filter(feed => feed.category === selectedCategory);
    }

    // 过滤掉无内容或异常的卡片
    feedsToRender = feedsToRender.filter(feed => feed.status === 'ok' && feed.items.length > 0);

    // console.log('Feeds to render:', feedsToRender);

    if (feedsToRender.length === 0) {
      return <div>没有找到符合条件的 RSS 源</div>;
    }

    return (
      <div className="rss-container">
        {feedsToRender.map((feed, index) => (
          <RSSCard 
            key={`${feed.title}-${index}`}
            title={feed.title} 
            items={feed.items} 
            lastUpdated={feed.lastUpdated}
            category={feed.category}
            isSelected={selectedCategory === feed.category}
            description={feed.description}
            status={feed.status}
            isMobile={isMobile}
          />
        ))}
      </div>
    );
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
  };

  const toggleViewMode = () => {
    setViewMode(prevMode => prevMode === 'card' ? 'stream' : 'card');
  };

  const renderContent = () => {
    // console.log('Rendering content, viewMode:', viewMode);
    // console.log('RSS data:', rssData);

    if (viewMode === 'card') {
      return renderRSSCards();
    } else {
      return <MixedContent rssData={rssData} categories={categories} />;
    }
  };

  const toggleSkinSelector = () => {
    setShowSkinSelector(!showSkinSelector);
  };

  const changeSkin = (newSkin: ThemeType) => {
    setCurrentTheme(newSkin);
    setShowSkinSelector(false);
    localStorage.setItem('selectedSkin', newSkin);
  };

  // 在 useEffect 中加载保存的皮肤设置
  useEffect(() => {
    const savedSkin = localStorage.getItem('selectedSkin') as ThemeType;
    if (savedSkin && themes[savedSkin]) {
      setCurrentTheme(savedSkin);
    }
  }, []);

  const skinOptions = [
    { id: 'nature', name: '自然', icon: '🍃', description: '舒适的绿色主题' },
    { id: 'default', name: '橙色', icon: '🍊', description: '经典橙色主题' },
    { id: 'monochrome', name: '黑白', icon: '🔲', description: '极简黑白主题' },
    { id: 'business', name: '商务', icon: '💼', description: '专业蓝色商务主题' },
  ];

  // 在 App 组件的 return 语句前添加以下代码
  useEffect(() => {
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
    document.getElementsByTagName('head')[0].appendChild(meta);
  }, []);

  return (
    <div className={`app ${isDarkMode ? 'dark-mode' : ''} theme-${currentTheme}`}>
      <header className="app-header">
        <h1>产品经理充电站</h1>
        <h2>精选RSS订阅，助力产品经理成长</h2>
        {!isMobile && (
          <div className="header-controls">
            <button onClick={toggleDarkMode} className="theme-toggle">
              {isDarkMode ? '🌞 日间模式' : '🌘 夜间模式'}
            </button>
            <button onClick={toggleViewMode} className="view-mode-toggle">
              {viewMode === 'card' ? '切换到流视图' : '切换到卡片视图'}
            </button>
            <button onClick={toggleSkinSelector} className="skin-toggle">
              选择皮肤
            </button>
            <button onClick={handleConfigClick} className="config-link">
              RSS源配置
            </button>
          </div>
        )}
      </header>
      
      {isMobile ? (
        <>
          {/* 移动端顶部分类导航 */}
          <nav className="category-nav-mobile">
            <div className="category-nav-scroll">
              <button onClick={() => handleCategoryClick(null)} className={selectedCategory === null ? 'active' : ''}>
                全部
              </button>
              {categories.map(category => (
                <button 
                  key={category} 
                  onClick={() => handleCategoryClick(category)}
                  className={selectedCategory === category ? 'active' : ''}
                >
                  {category}
                </button>
              ))}
            </div>
          </nav>
          
          <div className="main-content">
            <div className="content-area">
              {showConfig && isMobile && (
                <div className="mobile-config-warning">
                  请在电脑端访问配置页面
                </div>
              )}
              {!showConfig && renderContent()}
            </div>
          </div>
          
          {/* 移动端底部标签栏 */}
          <nav className="bottom-tab-bar">
            <button 
              onClick={toggleViewMode} 
              className={activeTab === 'viewMode' ? 'active' : ''}
            >
              {viewMode === 'card' ? '流式视图' : '卡片视图'}
            </button>
            <button 
              onClick={toggleDarkMode} 
              className={activeTab === 'theme' ? 'active' : ''}
            >
              {isDarkMode ? '日间模式' : '夜间模式'}
            </button>
            <button 
              onClick={toggleSkinSelector} 
              className={activeTab === 'skin' ? 'active' : ''}
            >
              皮肤
            </button>
            <button 
              onClick={handleConfigClick} 
              className={activeTab === 'config' ? 'active' : ''}
            >
              RSS源配置
            </button>
          </nav>
        </>
      ) : (
        <div className="main-content">
          {viewMode === 'card' && (
            <nav className="category-nav">
              <div className="category-nav-inner">
                <button onClick={() => handleCategoryClick(null)} className={selectedCategory === null ? 'active' : ''}>
                  全部
                </button>
                {categories.map(category => (
                  <button 
                    key={category} 
                    onClick={() => handleCategoryClick(category)}
                    className={selectedCategory === category ? 'active' : ''}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </nav>
          )}
          
          <div className="content-area">
            {showConfig && !isMobile && (
              <div className="config-container">
                <RSSConfig 
                  rssSources={rssSources} 
                  onSave={handleSaveConfig} 
                  onCategoriesChange={handleCategoriesChange} 
                />
              </div>
            )}
            {!showConfig && renderContent()}
          </div>
        </div>
      )}
      
      {/* 皮肤选择器 */}
      {showSkinSelector && (
        <div className="skin-selector-overlay" onClick={() => setShowSkinSelector(false)}>
          <div className="skin-selector" onClick={(e) => e.stopPropagation()}>
            <h3>选择皮肤</h3>
            <div className="skin-options">
              {skinOptions.map((option) => (
                <button
                  key={option.id}
                  onClick={() => changeSkin(option.id as ThemeType)}
                  className={`skin-option ${currentTheme === option.id ? 'active' : ''}`}
                >
                  <span className="skin-icon">{option.icon}</span>
                  <span className="skin-name">{option.name}</span>
                  <span className="skin-description">{option.description}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
      
      <footer className="app-footer">
        {showPasswordModal && (
          <div className="modal-overlay" onClick={() => setShowPasswordModal(false)}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <button className="close-modal" onClick={() => setShowPasswordModal(false)}>×</button>
              <h2>本地化自定义RSS源配置</h2>
              <QRCodeSVG value="http://b.btmcheck.com/s/bd5iY" />
              <p>进群获取密码</p>
              <form onSubmit={handlePasswordSubmit}>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="请输入密码"
                />
                <button type="submit">确认</button>
              </form>
            </div>
          </div>
        )}
        <div className="copyright">
          © 2024 产品经理充电站. 保留所有权利.
        </div>
      </footer>
      {showScrollButtons && (
        <div className="scroll-buttons">
          <button onClick={scrollToTop} className="scroll-button top" aria-label="滚动到顶部">
            ↑
          </button>
          <button onClick={scrollToBottom} className="scroll-button bottom" aria-label="滚动到底部">
            ↓
          </button>
        </div>
      )}
    </div>
  );
}

export default App;