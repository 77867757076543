import React, { useState, useRef, useEffect, useCallback } from 'react';
import { RSSSource, resetToDefaultSources, saveCategories as saveCategoriesConfig, loadCategories } from '../config/rssConfig';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import '../styles/RSSConfig.css';
import axios from 'axios';
import { saveCurrentAsDefault } from '../config/rssConfig';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

// 在文件顶部添加这个函数
function assertStatus(status: string): asserts status is 'ok' | 'error' | 'loading' {
  if (status !== 'ok' && status !== 'error' && status !== 'loading') {
    throw new Error(`Invalid status: ${status}`);
  }
}

interface RSSConfigProps {
  rssSources: RSSSource[];
  onSave: (sources: RSSSource[]) => void;
  onCategoriesChange: (categories: string[]) => void; // 添加这一行
}

interface SourceWithStatus extends RSSSource {
  status: 'ok' | 'error' | 'loading';
}

function RSSConfig({ rssSources, onSave, onCategoriesChange }: RSSConfigProps) {
  const [sources, setSources] = useState<SourceWithStatus[]>(
    rssSources.map(source => ({ ...source, status: 'loading' }))
  );
  const [categories, setCategories] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<'sources' | 'categories'>('sources');
  const [showModal, setShowModal] = useState(false);
  const [editingSource, setEditingSource] = useState<RSSSource | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [categoryToDelete, setCategoryToDelete] = useState<string | null>(null);
  const [deleteConfirmCount, setDeleteConfirmCount] = useState(0);
  const [reorderedCategories, setReorderedCategories] = useState<string[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [categoryOrders, setCategoryOrders] = useState<{[key: string]: number}>({});
  const [editingCategoryOrder, setEditingCategoryOrder] = useState<string | null>(null);
  const [tempCategoryOrders, setTempCategoryOrders] = useState<{[key: string]: number}>({});

  useEffect(() => {
    sources.forEach((source, index) => {
      const delay = Math.random() * 5000; // 0-5秒的随机延迟
      setTimeout(() => checkSourceStatus(index), delay);
    });
  }, []);

  useEffect(() => {
    const uniqueCategories = Array.from(new Set(sources.map(source => source.category)));
    setReorderedCategories(uniqueCategories);
  }, [sources]);

  useEffect(() => {
    const initialOrders = reorderedCategories.reduce((acc, category, index) => {
      acc[category] = index + 1;
      return acc;
    }, {} as {[key: string]: number});
    setTempCategoryOrders(initialOrders);
  }, [reorderedCategories]);

  const checkSourceStatus = async (index: number) => {
    try {
      await axios.get(`https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(sources[index].url)}`);
      updateSourceStatus(index, 'ok');
    } catch (error) {
      updateSourceStatus(index, 'error');
    }
  };

  const updateSourceStatus = (index: number, status: 'ok' | 'error' | 'loading') => {
    setSources(prevSources => {
      const newSources = [...prevSources];
      newSources[index] = { ...newSources[index], status };
      return newSources;
    });
  };

  const handleAddOrUpdateSource = (source: RSSSource) => {
    let newSources: SourceWithStatus[];
    if (editingSource) {
      newSources = sources.map(s => s.url === editingSource.url ? { ...source, status: 'loading' } : s);
    } else {
      newSources = [...sources, { ...source, status: 'loading' }];
    }
    setSources(newSources);
    
    // 更新分类
    const uniqueCategories = Array.from(new Set(newSources.map(s => s.category)));
    setReorderedCategories(uniqueCategories);
    const newCategoryOrders = { ...categoryOrders };
    uniqueCategories.forEach((category, index) => {
      if (!(category in newCategoryOrders)) {
        newCategoryOrders[category] = index + 1;
      }
    });
    setCategoryOrders(newCategoryOrders);
    
    onSave(newSources.map(({ status, ...rest }) => rest));
    setShowModal(false);
    setEditingSource(null);
    
    // 检查新添加或更新的源的状态
    const index = newSources.findIndex(s => s.url === source.url);
    if (index !== -1) {
      setTimeout(() => checkSourceStatus(index), Math.random() * 2000);
    }
  };

  const handleEditSource = (source: RSSSource) => {
    setEditingSource(source);
    setShowModal(true);
  };

  const handleRemoveSource = (urlToRemove: string) => {
    setShowDeleteConfirm(urlToRemove);
  };

  const confirmDelete = (urlToRemove: string) => {
    const newSources = sources.filter(source => source.url !== urlToRemove);
    setSources(newSources);
    onSave(newSources); // 自动保存
    setShowDeleteConfirm(null);
  };

  const handleReset = () => {
    const defaultSources: SourceWithStatus[] = resetToDefaultSources().map(source => {
      const status = 'loading';
      assertStatus(status);
      return { ...source, status };
    });
    setSources(defaultSources);
    onSave(resetToDefaultSources());
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target?.result as string;
      let parsedData: RSSSource[] = [];

      try {
        if (file.name.endsWith('.csv')) {
          Papa.parse(content, {
            complete: (results) => {
              parsedData = (results.data as any[]).slice(1).map((row) => ({
                url: row[0],
                name: row[1],
                category: row[2],
                description: row[3] || '' // 添加这一行
              }));
              addParsedSources(parsedData);
            },
          });
        } else if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
          const workbook = XLSX.read(content, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(sheet);
          parsedData = data.map((row: any) => ({
            url: row.url,
            name: row.name,
            category: row.category,
            description: row.description || '' // 添加这一行
          }));
          addParsedSources(parsedData);
        }
        alert('导入成功！');
      } catch (error) {
        console.error('导入失败:', error);
        alert('导入失败，请检查文件格式是否正确。');
      }
    };

    if (file.name.endsWith('.csv')) {
      reader.readAsText(file, 'UTF-8');
    } else {
      reader.readAsBinaryString(file);
    }
  };

  const addParsedSources = (parsedSources: RSSSource[]) => {
    const newSources = [...sources];
    parsedSources.forEach((source) => {
      if (!newSources.some((s) => s.url === source.url)) {
        newSources.push({ ...source, status: 'loading' });
      }
    });
    setSources(newSources);
    onSave(newSources.map(({ status, ...rest }) => rest)); // 自动保存，但移除 status
  };

  const handleExportCSV = () => {
    const csvData = sources.map(source => [source.url, source.name, source.category, source.description]);
    csvData.unshift(['URL', 'Name', 'Category', 'Description']);
    const csv = Papa.unparse(csvData);
    const BOM = '\uFEFF';
    const csvContent = BOM + csv;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'rss_sources.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleSaveAsDefault = () => {
    saveCurrentAsDefault();
    alert('当前RSS源已保存为新的默认配置');
  };

  const handleCategoryAdd = (newCategory: string) => {
    if (!categories.includes(newCategory)) {
      const updatedCategories = [...categories, newCategory];
      setCategories(updatedCategories);
      saveCategoriesConfig(updatedCategories);
      onCategoriesChange(updatedCategories);
    }
  };

  const handleCategoryDelete = (category: string) => {
    setCategoryToDelete(category);
    setDeleteConfirmCount(0);
  };

  const confirmCategoryDelete = () => {
    if (deleteConfirmCount < 2) {
      setDeleteConfirmCount(prev => prev + 1);
    } else {
      const updatedCategories = reorderedCategories.filter(c => c !== categoryToDelete);
      setReorderedCategories(updatedCategories);
      setCategoryToDelete(null);
      setDeleteConfirmCount(0);
    }
  };

  const handleCategoryMove = (categoryIndex: number, newPosition: number) => {
    const newCategories = [...reorderedCategories];
    const [movedCategory] = newCategories.splice(categoryIndex, 1);
    newCategories.splice(newPosition, 0, movedCategory);
    setReorderedCategories(newCategories);
    saveCategoriesChanges(); // 移除参数
  };

  const handleCategoryOrderEdit = (category: string) => {
    setEditingCategoryOrder(category);
    setTempCategoryOrders(prev => ({ ...prev, [category]: categoryOrders[category] || 0 }));
  };

  const handleCategoryOrderChange = (category: string, order: string) => {
    const numericOrder = parseInt(order, 10);
    if (!isNaN(numericOrder)) {
      setCategoryOrders(prev => ({ ...prev, [category]: numericOrder }));
    }
  };

  const handleCategoryOrderSave = (category: string) => {
    setCategoryOrders(prev => ({ ...prev, [category]: tempCategoryOrders[category] }));
    setEditingCategoryOrder(null);
  };

  const saveCategoriesChanges = useCallback((updatedCategories: string[] = reorderedCategories) => {
    saveCategoriesConfig(updatedCategories);
    onCategoriesChange(updatedCategories);
    
    const updatedSources = sources.map(source => ({
      ...source,
      category: updatedCategories.includes(source.category) ? source.category : '未分类'
    }));
    setSources(updatedSources);
    onSave(updatedSources.map(({ status, ...rest }) => rest));

    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  }, [reorderedCategories, sources, onCategoriesChange, onSave]);

  const onDragEnd = useCallback((result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(reorderedCategories);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setReorderedCategories(items);
    saveCategoriesChanges(items);
  }, [reorderedCategories, saveCategoriesChanges]);

  const getCategoryId = useCallback((category: string) => {
    return encodeURIComponent(category).replace(/%/g, '_');
  }, []);

  const getCategoryCount = (category: string) => {
    return sources.filter(source => source.category === category).length;
  };

  const handleExportClick = () => {
    handleExportCSV();
  };

  const handleImportClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="rss-config">
      <div className="config-sidebar">
        <h2>RSS 源配置</h2>
        <button 
          onClick={() => setActiveTab('sources')} 
          className={activeTab === 'sources' ? 'active' : ''}
        >
          源配置
        </button>
        <button 
          onClick={() => setActiveTab('categories')} 
          className={activeTab === 'categories' ? 'active' : ''}
        >
          分类配置
        </button>
      </div>

      <div className="config-content">
        {activeTab === 'sources' ? (
          <>
            <div className="config-actions">
              <button onClick={() => setShowModal(true)}>添加新RSS源</button>
              <button onClick={() => fileInputRef.current?.click()}>导入 CSV/Excel</button>
              <button onClick={handleExportCSV}>导出 CSV</button>
              <button onClick={handleSaveAsDefault}>保存为默认配置</button>
              <button onClick={handleReset}>重置为默认配置</button>
            </div>
            <p className="config-hint">
              建议先导出CSV,清除内容,然后按照格式新增自己的RSS源
            </p>
            <input
              type="file"
              accept=".csv,.xlsx,.xls"
              onChange={handleFileUpload}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            <ul className="source-list">
              {sources.map((source, index) => (
                <li key={index} className="source-item">
                  <div className="source-info">
                    <div className="source-name">{source.name}</div>
                    <div className="source-category">{source.category}</div>
                    <div className="source-url">{source.url}</div>
                    {source.description && <div className="source-description">{source.description}</div>}
                  </div>
                  <div className="source-actions">
                    <span className={`source-status ${source.status}`}>
                      {source.status === 'ok' ? '正常' : source.status === 'error' ? '异常' : '加载中'}
                    </span>
                    <button onClick={() => handleEditSource(source)} className="edit-button">编辑</button>
                    <button onClick={() => handleRemoveSource(source.url)} className="delete-button">删除</button>
                    <button onClick={() => checkSourceStatus(index)} className="refresh-button">刷新</button>
                  </div>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <>
            <h2>分类配置</h2>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="categories">
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef} className="category-list">
                    {reorderedCategories.map((category, index) => (
                      <Draggable key={getCategoryId(category)} draggableId={getCategoryId(category)} index={index}>
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="category-item"
                          >
                            <span>{category} ({getCategoryCount(category)})</span>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            <button onClick={() => saveCategoriesChanges()} className="save-categories-button">保存分类更改</button>
          </>
        )}
      </div>

      {showModal && (
        <SourceModal
          source={editingSource}
          onSave={handleAddOrUpdateSource}
          onClose={() => {
            setShowModal(false);
            setEditingSource(null);
          }}
          categories={categories}
        />
      )}

      {showDeleteConfirm && (
        <div className="modal-overlay">
          <div className="modal delete-confirm-modal">
            <h3>确认删除</h3>
            <p>您确定要删除这个RSS源吗？</p>
            <div className="modal-buttons">
              <button onClick={() => confirmDelete(showDeleteConfirm)} className="confirm-delete">确认</button>
              <button onClick={() => setShowDeleteConfirm(null)} className="cancel-delete">取消</button>
            </div>
          </div>
        </div>
      )}
      {showToast && <div className="toast">分类顺序已更新！</div>}
    </div>
  );
}

interface SourceModalProps {
  source: RSSSource | null;
  onSave: (source: RSSSource) => void;
  onClose: () => void;
  categories: string[];
}

function SourceModal({ source, onSave, onClose, categories }: SourceModalProps) {
  const [name, setName] = useState(source?.name || '');
  const [url, setUrl] = useState(source?.url || '');
  const [category, setCategory] = useState(source?.category || '');
  const [description, setDescription] = useState(source?.description || '');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave({ name, url, category, description });
  };

  return (
    <div className="modal-overlay">
      <div className="modal source-modal">
        <h3>{source ? '编辑RSS源' : '添加新RSS源'}</h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="RSS源名称"
            required
          />
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="RSS源URL"
            required
          />
          <input
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            placeholder="分类"
            required
          />
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="说明（可选）"
          />
          <div className="modal-buttons">
            <button type="submit">保存</button>
            <button type="button" onClick={onClose}>取消</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RSSConfig;