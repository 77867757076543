export interface ThemeColors {
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  accentColor: string;
  textColor: string;
  borderColor: string;
  shadowColor: string;
  cardBackground: string;
  headerTextColor: string;
  footerBackground: string;
  categoryColor: string;
  updateTimeColor: string;
  buttonTextColor: string;
  cardHeaderBackground: string;
  cardHeaderBackgroundLight: string;
  navButtonHoverBackground: string;
  navButtonActiveBackground: string;
}

export interface Theme {
  light: ThemeColors;
  dark: ThemeColors;
}

export const themes: Record<string, Theme> = {
  nature: {
    light: {
      primaryColor: '#4CAF50',
      secondaryColor: '#8BC34A',
      backgroundColor: '#F0F4F0',  // 稍微加了一点灰色
      accentColor: '#009688',
      textColor: '#33691E',
      borderColor: '#C8E6C9',
      shadowColor: 'rgba(76, 175, 80, 0.15)',
      cardBackground: '#FFFFFF',
      headerTextColor: '#2E7D32',
      footerBackground: '#E8F5E9',
      categoryColor: '#558B2F',
      updateTimeColor: '#689F38',
      buttonTextColor: 'white',
      cardHeaderBackground: '#A5D6A7',
      cardHeaderBackgroundLight: '#C8E6C9',
      navButtonHoverBackground: '#81C784',
      navButtonActiveBackground: '#4CAF50',
    },
    dark: {
      primaryColor: '#66BB6A',
      secondaryColor: '#81C784',
      backgroundColor: '#1E2723',
      accentColor: '#26A69A',
      textColor: '#E0E0E0',
      borderColor: '#2E3E32',  // 调暗了边框颜色
      shadowColor: 'rgba(102, 187, 106, 0.1)',  // 减少阴影不透明度
      cardBackground: '#263238',
      headerTextColor: '#81C784',
      footerBackground: '#1B2820',
      categoryColor: '#A5D6A7',
      updateTimeColor: '#607D8B',  // 调暗了日期颜色
      buttonTextColor: '#121212',
      cardHeaderBackground: '#2E3E32',  // 调暗了卡片头部背景
      cardHeaderBackgroundLight: '#37474F',
      navButtonHoverBackground: '#4CAF50',
      navButtonActiveBackground: '#388E3C',
    },
  },
  default: {
    light: {
      primaryColor: '#FFA500',
      secondaryColor: '#FFB74D',  // 改为更柔和的橙色
      backgroundColor: '#FFF8E1',  // 稍微加了一点灰色
      accentColor: '#FF9800',  // 改为更柔和的橙色
      textColor: '#333333',
      borderColor: '#FFE0B2',
      shadowColor: 'rgba(255, 165, 0, 0.15)',
      cardBackground: '#FFFFFF',
      headerTextColor: '#FF8C00',
      footerBackground: '#FFF3E0',
      categoryColor: '#F57C00',
      updateTimeColor: '#FF8F00',
      buttonTextColor: 'white',
      cardHeaderBackground: '#FFE0B2',
      cardHeaderBackgroundLight: '#FFF3E0',
      navButtonHoverBackground: '#FFB74D',
      navButtonActiveBackground: '#FFA000',
    },
    dark: {
      primaryColor: '#FFA500',
      secondaryColor: '#FFB74D',
      backgroundColor: '#1A1A1A',
      accentColor: '#FF6347',
      textColor: '#E0E0E0',
      borderColor: '#2C2C2C',  // 调暗了边框颜色
      shadowColor: 'rgba(255, 165, 0, 0.1)',  // 减少阴影不透明度
      cardBackground: '#2A2A2A',
      headerTextColor: '#FFB84D',
      footerBackground: '#222',
      categoryColor: '#FF4500',
      updateTimeColor: '#808080',  // 调暗了日期颜色
      buttonTextColor: '#121212',
      cardHeaderBackground: '#333333',  // 调暗了卡片头部背景
      cardHeaderBackgroundLight: '#3D3D3D',
      navButtonHoverBackground: '#FF8C00',
      navButtonActiveBackground: '#FF6F00',
    },
  },
  monochrome: {
    light: {
      primaryColor: '#333333',
      secondaryColor: '#666666',
      backgroundColor: '#F5F5F5',  // 稍微加了一点灰色
      accentColor: '#999999',
      textColor: '#333333',
      borderColor: '#E0E0E0',
      shadowColor: 'rgba(0, 0, 0, 0.15)',
      cardBackground: '#FFFFFF',
      headerTextColor: '#000000',
      footerBackground: '#EEEEEE',
      categoryColor: '#555555',
      updateTimeColor: '#777777',
      buttonTextColor: 'white',
      cardHeaderBackground: '#D6D6D6',
      cardHeaderBackgroundLight: '#E6E6E6',
      navButtonHoverBackground: '#CCCCCC',
      navButtonActiveBackground: '#AAAAAA',
    },
    dark: {
      primaryColor: '#FFFFFF',
      secondaryColor: '#AAAAAA',
      backgroundColor: '#121212',
      accentColor: '#CCCCCC',
      textColor: '#E0E0E0',
      borderColor: '#2C2C2C',  // 调暗了边框颜色
      shadowColor: 'rgba(255, 255, 255, 0.1)',  // 减少阴影不透明度
      cardBackground: '#1E1E1E',
      headerTextColor: '#FFFFFF',
      footerBackground: '#1A1A1A',
      categoryColor: '#CCCCCC',
      updateTimeColor: '#808080',  // 调暗了日期颜色
      buttonTextColor: '#121212',
      cardHeaderBackground: '#2A2A2A',
      cardHeaderBackgroundLight: '#333333',
      navButtonHoverBackground: '#444444',
      navButtonActiveBackground: '#666666',
    },
  },
  business: {
    light: {
      primaryColor: '#0077B5',
      secondaryColor: '#00A0DC',
      backgroundColor: '#F0F3F6',  // 稍微加了一点灰色
      accentColor: '#006097',
      textColor: '#333333',
      borderColor: '#E1E9EF',
      shadowColor: 'rgba(0, 119, 181, 0.15)',
      cardBackground: '#FFFFFF',
      headerTextColor: '#0077B5',
      footerBackground: '#E1E9EF',
      categoryColor: '#0077B5',
      updateTimeColor: '#006097',
      buttonTextColor: 'white',
      cardHeaderBackground: '#E1E9EF',
      cardHeaderBackgroundLight: '#F3F6F8',
      navButtonHoverBackground: '#00A0DC',
      navButtonActiveBackground: '#0077B5',
    },
    dark: {
      primaryColor: '#00A0DC',
      secondaryColor: '#0077B5',
      backgroundColor: '#1C2938',
      accentColor: '#00A0DC',
      textColor: '#E0E0E0',
      borderColor: '#2C3E50',  // 调暗了边框颜色
      shadowColor: 'rgba(0, 160, 220, 0.1)',  // 减少阴影不透明度
      cardBackground: '#2C3E50',
      headerTextColor: '#00A0DC',
      footerBackground: '#1C2938',
      categoryColor: '#00A0DC',
      updateTimeColor: '#7F8C8D',  // 调暗了日期颜色
      buttonTextColor: '#FFFFFF',
      cardHeaderBackground: '#34495E',
      cardHeaderBackgroundLight: '#2C3E50',
      navButtonHoverBackground: '#0077B5',
      navButtonActiveBackground: '#006097',
    },
  },
};

export type ThemeType = keyof typeof themes;